/*

---
title: Icons
name: icons
category: Javascript
---

*/

define( 'app/icons',['app/utils'] , function( UTILS )
{
	// --------------------------------------------------
	// Creates a new icon

	var ICON = function( $container )
	{
		this.$container = $container;
		this.icon_identifier = null;

		// Extract the identifier from the container's class(es)

		var class_attribute = $container.getAttribute( 'class' );

		var raw_classes = class_attribute.split( ' ' );

		raw_classes.forEach( function( class_name )
		{
			var matches = class_name.match( /^c-icon--.*/ );

			if( matches )
			{
				var suffix = class_name.replace( /^c-icon--/ , '' );

				// Skip if this class is a modifier
				if( this.modifiers.indexOf( suffix ) !== -1 ) return;

				// Otherwise we have our icon
				this.icon_identifier = suffix;
			}

		}.bind( this ));

		// Make the URL to the SVG file

		var url = '/img/icons/' + this.icon_identifier.split( '#' ).join( '/' ) + '.svg';

		// Use an absolute URL if we're in production
		if( !UTILS.isDev() ) url = 'https://www.york.ac.uk/static/stable' + url;

		// Download the SVG and inline it

		fetch( url )
			.then( function( response )
			{
				if( response.status !== 200 ) throw Error( 'Icon not found at URL: ' + url );
				return response.text();
			})
			.then( function( content )
			{
				$container.innerHTML = content;
			})
			.catch( function( error )
			{
				console.error( error );
			});
	};

	// --------------------------------------------------

	ICON.prototype.modifiers =
	[
		// Sizing
		'hf', // 50%
		'lg', // 133%
		'2x', // 200%
		'3x', // 300%
		'4x', // 400%
		'5x', // 500%

		// Full width (isn't really)
		'fw',

		// With a margin before or after
		'before',
		'after',

		// List-related stuff
		'li',
		'ul',
	];

	// --------------------------------------------------

	var ICONS = function()
	{
		// Initial pass on the whole document

		var $icons = document.querySelectorAll( '.c-icon' );

		$icons.forEach( function( $icon )
		{
			new ICON( $icon );
		});

		// Set up a mutation observer to handle any new icons

		var observerConfig =
		{
			attributes: false,
			childList: true,
			subtree: true,
			characterData: false
		};

		var observer = new MutationObserver( function( mutations )
		{
			mutations.forEach( function( mutation )
			{
				// type = 'childList' -> Nodes added or removed: ignore other types of mutation
				if( mutation.type != 'childList' ) return;

				// We only want to do anything if a node is added
				if( !mutation.addedNodes || mutation.addedNodes.length == 0 ) return;

				mutation.addedNodes.forEach( function( $node )
				{
					// If this is an icon initialise it now...

					if( $node.classList && $node.classList.contains( 'c-icon' ) )
					{
						new ICON( $node );
						return;
					}

					// ...otherwise check for icons in the new node

					if( $node.querySelectorAll )
					{
						var $icons = $node.querySelectorAll( '.c-icon' );

						$icons.forEach( function( $icon )
						{
							new ICON( $icon );
						});
					}
				});
			});
		});

		observer.observe( document.body , observerConfig );
	};

	// --------------------------------------------------

	return ICONS;

	// --------------------------------------------------
});

